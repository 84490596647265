import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import cx from 'classnames';
import * as s from './FooterContainer.module.scss';

const FooterContainer = (props) => {
  const {
    children,
  } = props;

  return (
    <div className={s.root}>
      {children}
    </div>
  );
}

FooterContainer.propTypes = {
}

FooterContainer.defaultProps = {
};

export default FooterContainer;
