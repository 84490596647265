import React from 'react';
import PropTypes from 'prop-types';
import FooterBottom from 'components/FooterBottom';
import cx from 'classnames';
import * as s from './Bottom.module.css';

class Bottom extends React.Component {
  static propTypes = {
    noCommunity: PropTypes.bool
  }

  render () {
    const { noCommunity, children } = this.props;

    return (
      <div className={cx("bottom", s.bottom)}>
        {children
          ? children
          : <FooterBottom />
        }
      </div>
    )
  }
}

export default Bottom;
