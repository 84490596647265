import React, { Children, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  DOMAIN_TYPES,
} from '@hiveon/modules';
//
import { LINK_TYPES } from 'constants/index';
import { getIntlUrl } from 'helpers/index';
//
import _get from 'lodash/get';
import cx from 'classnames';
import * as s from './FooterNavBlock.module.scss';

const FooterNavBlock = (props) => {
  const {
    data,
    className = '',
    titlClass = '',
    children,
    intl,
  } = props;

  return (
    <div className={cx(s.root, className)}>
      <h4 className={cx(s.title, titlClass)}>
        <FormattedMessage id={data.title} />
      </h4>

      {
        children ? children : null
      }

      {
        data.items ? (
          <ul className={s.items}>
            {data.items.map((item, idx) => (
              <li className={s.item} key={`${data.title}-footer-item-${idx}`}>
                <Link
                  to={getIntlUrl(item, intl.locale)}
                  href={item.href}
                  className={s.link}
                  targetDomainType={item.domainType}
                  email={item.linkType === LINK_TYPES.email}
                  external={item.domainType === DOMAIN_TYPES.external}
                  {...(item.gtmParams || {})}
                >
                  {
                    item.noIntl ? item.title : (
                      <FormattedMessage id={item.title} />
                    )
                  }
                </Link>
              </li>
            ))}
          </ul>
        ) : null
      }
    </div>
  );
}

FooterNavBlock.propTypes = {

}

FooterNavBlock.defaultProps = {

};

export default injectIntl(FooterNavBlock);
