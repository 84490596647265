// extracted by mini-css-extract-plugin
export var root = "FooterBottom-module--root--iq6qK";
export var separator = "FooterBottom-module--separator--ct6NT";
export var banner = "FooterBottom-module--banner--8Jo1l";
export var bannerImageHolder = "FooterBottom-module--bannerImageHolder--QqKE5";
export var bannerImage = "FooterBottom-module--bannerImage--N5XO7";
export var bannerDetails = "FooterBottom-module--bannerDetails--Tz9vt";
export var bannerTitle = "FooterBottom-module--bannerTitle--oU3ji";
export var bannerLink = "FooterBottom-module--bannerLink--znIQ0";
export var copy = "FooterBottom-module--copy--LGFY9";
export var navigation = "FooterBottom-module--navigation--6RQov";
export var navCell = "FooterBottom-module--navCell--NEQVz";
export var col1 = "FooterBottom-module--col1--uJDvm";
export var col2 = "FooterBottom-module--col2--8LeZO";
export var navContentAndMobileCell = "FooterBottom-module--navContentAndMobileCell--Qshrj";
export var navSupportCell = "FooterBottom-module--navSupportCell--iaW2A";
export var navBlock = "FooterBottom-module--navBlock--CA1Rg";
export var navContentBlock = "FooterBottom-module--navContentBlock--B9T8l";
export var navMobileBlock = "FooterBottom-module--navMobileBlock--SECyZ";
export var navOthersBlock = "FooterBottom-module--navOthersBlock--yYc4U";
export var mobileBadge = "FooterBottom-module--mobileBadge--JK6Mf";
export var mobileBadgeTitle = "FooterBottom-module--mobileBadgeTitle--IQzVX";
export var mobile = "FooterBottom-module--mobile--5ddOe";
export var desktop = "FooterBottom-module--desktop--LWI9O";