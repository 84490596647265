import React from 'react';
import PropTypes from 'prop-types';
import FooterNavBlock from './FooterNavBlock';
import FooterSocials from './FooterSocials';
import FooterContainer from './FooterContainer';
import { FOOTER_NAV, FOOTER_SOCIALS } from './navigation';
import cx from 'classnames';
import * as s from './FooterBottom.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  StoreBadge,
  Content,
} from '@hiveon/modules';

const FooterBottom = (props) => {
  const {
    intl,
  } = props;

  return (
    <div className={s.root}>
      <FooterContainer>
        <Content>
          <div className={s.navigation}>
            <div className={cx(s.navCell, s.col1)}>
              <FooterNavBlock
                className={s.navBlock}
                data={FOOTER_NAV.hiveonOS}
              />
            </div>
            <div className={cx(s.navCell, s.navContentAndMobileCell, s.col2)}>
              <FooterNavBlock
                className={cx(s.navBlock, s.navContentBlock)}
                data={FOOTER_NAV.content}
              />
              <FooterNavBlock
                className={cx(s.navBlock, s.navMobileBlock)}
                data={FOOTER_NAV.mobile}
                titlClass={s.mobileBadgeTitle}
              >
                <StoreBadge type="apple" className={s.mobileBadge} locale={intl.locale} />
                <StoreBadge type="google" className={s.mobileBadge} locale={intl.locale} />
              </FooterNavBlock>
            </div>
            <div className={s.navCell}>
              <FooterNavBlock
                className={cx(s.navBlock, s.community)}
                data={FOOTER_NAV.community}
              />
            </div>
            <div className={cx(s.navCell, s.navSupportCell)}>
              <FooterNavBlock
                className={cx(s.navBlock, s.navOthersBlock)}
                data={FOOTER_NAV.other}
              />
            </div>
            <div className={cx(s.navCell, s.navSupportCell)}>
              <FooterNavBlock
                className={s.navBlock}
                data={FOOTER_NAV.support}
              />
            </div>
          </div>
          <FooterSocials socials={FOOTER_SOCIALS}/>
        </Content>
      </FooterContainer>

      <div className={s.separator}></div>

      <FooterContainer>
        <Content>
            <div className={cx(s.copy)}>© 2022 Hiveon</div>
          </Content>
      </FooterContainer>
    </div>
  );
}

FooterBottom.propTypes = {
};

FooterBottom.defaultProps = {
};

export default injectIntl(FooterBottom);
