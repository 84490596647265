import {
  DOMAIN_TYPES,
} from '@hiveon/modules';
import { LINK_TYPES } from 'constants/index';

import { Facebook, Telegram, Youtube, Blog, Discord, Twitter, Hive, Github } from './FooterSocials/icons'

export const FOOTER_NAV = {
  'hiveonOS': {
    title: 'Hive OS',
    items: [
      {
        title: 'Getting started',
        url: '/install',
        urlsIntl: {
          ru: '/install_ru/',
          zh: '/install_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'Why Hive OS',
        url: '/features',
        urlsIntl: {
          ru: '/features_ru/',
          zh: '/features_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'Pricing',
        url: '/pricing',
        urlsIntl: {
          ru: '/pricing_ru/',
          zh: '/pricing_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'Referral Program',
        url: '/referral',
        urlsIntl: {
          ru: '/referral_ru/',
          zh: '/referral_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'Contacts',
        url: '/contact',
        urlsIntl: {
          ru: '/contact_ru/',
          zh: '/contact_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'Hive OS API',
        url: 'https://hiveon.com/forum/t/hive-api-v2/4490',
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'Terms of Use',
        url: '/terms',
        urlsIntl: {
          ru: '/terms_ru/',
          zh: '/terms_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'Bug Bounty',
        url: '/bugbounty',
        urlsIntl: {
          ru: '/bugbounty_ru/',
          zh: '/bugbounty_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'Service Status',
        url: 'https://status.hiveos.farm/',
        domainType: DOMAIN_TYPES.external,
      },
    ],
  },
  'content': {
    title: 'footerNav.content',
    items: [
      {
        title: 'Github',
        url: 'https://github.com/minershive',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'Changelog',
        url: '/changelog',
        urlsIntl: {
          ru: '/changelog_ru/',
          zh: '/changelog_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'footerNav.statistics',
        url: '/statistics',
        urlsIntl: {
          ru: '/statistics_ru/',
          zh: '/statistics_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'footerNav.blog',
        url: '/blog',
        urlsIntl: {
          ru: '/blog_ru/',
          zh: '/blog_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
        gtmParams: {
          'data-gtm-category': 'site_footer',
          'data-gtm-action': 'blog_click',
          'data-gtm-label': JSON.stringify({ button: 'blog' }),
        },
      },
    ],
  },
  'mobile': {
    title: 'footerNav.mobile',
  },
  'community': {
    title: 'footerNav.community',
    items: [
      {
        title: 'footerNav.forum',
        url: '/forum/',
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'EN',
        url: 'https://t.me/hiveoschat_en',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'RU',
        url: 'https://t.me/hiveoschat',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: '中文',
        url: 'https://t.me/hiveonos',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: '日本',
        url: 'https://t.me/hiveoschat_jp',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'FR',
        url: 'https://t.me/hiveoschat_fr',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'IT',
        url: 'https://t.me/hiveoschat_italia',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'ES',
        url: 'https://t.me/hiveoschat_es',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      {
        title: 'GE',
        url: 'https://t.me/hiveoschat_ge',
        domainType: DOMAIN_TYPES.external,
        noIntl: true,
      },
      // {
      //   title: 'Discord',
      //   url: 'https://discord.gg/CVZeZdn',
      //   domainType: DOMAIN_TYPES.external,
      //   noIntl: true,
      // },
    ],
  },
  'support': {
    title: 'footerNav.support',
    items: [
      {
        title: 'bee@hiveos.farm',
        url: 'mailto:bee@hiveos.farm',
        domainType: DOMAIN_TYPES.external,
        linkType: LINK_TYPES.email,
        noIntl: true,
      },
      {
        title: 'footerNav.kb',
        url: '/knowledge-base',
        urlsIntl: {
          ru: '/knowledge-base_ru/',
          zh: '/knowledge-base_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
    ],
  },
  'other': {
    title: 'footerNav.other',
    items: [
      {
        title: 'footerNav.hiveonPool',
        url: 'https://multisite.hiveon.dev/pool/',
        urlsIntl: {
          ru: '/ru/',
          zh: '/zh/',
        },
        domainType: DOMAIN_TYPES.external,
      },
      {
        title: 'ASIC.Firmware',
        url: '/asic',
        urlsIntl: {
          ru: '/asic_ru/',
          zh: '/asic_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
      {
        title: 'ASIC.Hub',
        url: '/asichub',
        urlsIntl: {
          ru: '/asichub_ru/',
          zh: '/asichub_zh/',
        },
        domainType: DOMAIN_TYPES.hiveon,
      },
    ],
  },
};

export const FOOTER_SOCIALS = [
  {
    title: 'Telegram',
    url: 'https://t.me/hiveon_official',
    Icon: Telegram,
  },
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/hiveon/',
    Icon: Facebook,
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/hiveonofficial',
    Icon: Twitter,
  },
  {
    title: 'Youtube',
    url: 'https://www.youtube.com/HiveOS',
    Icon: Youtube,
  },
  {
    title: 'Discord',
    url: 'https://discord.gg/CVZeZdn',
    Icon: Discord,
  },
  {
    title: 'Github',
    url: 'https://github.com/minershive',
    Icon: Github,
  },
  {
    title: 'Blog',
    url: '/blog/',
    Icon: Blog,
    gtmParams: {
      'data-gtm-category': 'site_footer',
      'data-gtm-action': 'blog_click',
      'data-gtm-label': JSON.stringify({ button: 'blog' }),
    },
  },
  {
    title: 'Forum',
    url: '/forum/',
    Icon: Hive,
  },
]
